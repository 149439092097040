// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Only if you installed Axios

function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace the URL with your API endpoint
        const response = await axios.get('http://nakuljavali.com:6574/list');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once after the initial render

  return (
    <div>
      <h1>API Demo</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {data && data.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default App;